<template>
    <ul>
        <router-link to="/container">台湾</router-link>
        <!-- <a href="http://192.168.0.103:8989/#screen=skpnsgcu1egzzf4" target="_self">台湾</a> -->
        <li @click="toTai">大陆</li>
    </ul>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        toDaLu() {
            // to大陆
        },
        toTai() {
            // to台湾
        }
    }
}
</script>
<style lang="scss" scoped>

</style>